import React from 'react'

const Toc = ({ sessions, clss }) => {
  return <ul className={clss}>
    {sessions.map(title => {
      title = title.slice(9)
      return <li key={`toc-${title}`}><a href={`#${encodeURI(title)}`}>{title}</a></li>
    })}
  </ul>
}

export default Toc